<template>
  <el-dialog :title="title" :visible.sync="visible" class="businessDictEdit" :close-on-click-modal="false" :close-on-press-escape="false" @close="close">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="submit" label-width="120px">
      <el-form-item prop="name" label="业务字典名称 :">
        <el-input v-model="dataForm.name" size="small" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item prop="typeId" label="业务字典类型">
        <el-select v-model="dataForm.typeId" placeholder="业务字典类型" clearable size="small" >
          <el-option
              v-for="item in typeIdList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import debounce from 'lodash/debounce'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'businessDictEdit',
  data () {
    return {
      visible: false,
      id: null,
      dataForm: {
        id: '',
        name: '',
        typeId: ''
      },
      dataRule: {
        name: [{ required: true, message: '请输入业务字典名称', trigger: 'blur' }],
        typeId: [{ required: true, message: '请选择业务字典类型', trigger: 'blur' }]
      },
      typeList: [],
      typeIdList: []
    }
  },
  computed: {
    title () {
      return `${this.dataForm.id ? '修改' : '新增'}业务字典`
    }
  },
  methods: {
    init (item) {
      this.getTypeList()
      if (item && item.id) {
        this.getInfo(item.id)
      } else {
        this.id = null
        let form = this.$options.data().dataForm
        this.$set(this, 'dataForm', form)
      }
      this.visible = true
      this.$nextTick(() => {
        this.$refs.dataForm.clearValidate()
      })
    },
    // 获取详情
    getInfo (id) {
      this.$http.get(`/businessDict/${id}`).then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(code, msg, data)
        if (code === 0) {
          let form = cloneDeep(data)
          this.$set(this, 'dataForm', form)
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 业务字典类型下拉框
    getTypeList () {
      this.$http.get('/businessDict/type').then(({ data: res }) => {
        let { code, msg, data } = res
        // console.log(res)
        if (code === 0 && data) {
          this.typeIdList = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    // 关闭窗口
    close () {
      this.visible = false
      this.$emit('reload')
    },
    // 提交数据
    submit: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) return false
        let func = this.dataForm.id ? 'put' : 'post'
        let form = cloneDeep(this.dataForm)
        this.$http[func]('/businessDict', form).then(({ data: res }) => {
          let { code, msg } = res
          if (code !== 0) {
            this.$message.error(msg)
          } else {
            this.close()
          }
        })
      })
    }, 1000, { 'leading': true, 'trailing': false })
  }
}
</script>
<style lang="scss">
.businessDictEdit {
  .fullWidth {
    width: 100%;
  }
  .el-select {
    width: 100%;
  }
  .el-form-item {
    margin-bottom: 16px;
  }
}
</style>
